<template>
    <div>
        <el-card>
            <div class="global-search-box">
                <div class="search-content">
                    <div class="search-item">
                        <label class="search-label">操作人：</label>
                        <el-select v-model="steffIndex" filterable clearable placeholder="请选择">
                            <el-option v-for="(item, index) in staffList" :key="item.id" :label="item.user_name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="search-item">
                        <label class="search-label">操作模块：</label>
                        <el-select v-model="resourceIndex" filterable clearable placeholder="请选择">
                            <el-option v-for="(item, index) in resourceList" :key="item.name" :label="item.name"
                                :value="item.name">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="search-item">
                        <label class="search-label">关联事项：</label>
                        <el-input type="text" v-model="relatedMatters" placeholder="请输入关键词查找" />
                    </div>
                    <div class="search-item">
                        <label class="search-label">操作时间：</label>
                        <el-date-picker v-model="noticeTime" type="datetimerange" :picker-options="pickerOptionLater"
                            start-placeholder="开始日期" format="yyyy-MM-dd" end-placeholder="结束日期">
                        </el-date-picker>

                    </div>
                </div>
                <div class="serach-buttom-button">
                    <el-button type="primary" @click="handleSumbited">搜索</el-button>
                    <el-button @click="resetting()">重置条件</el-button>
                    <el-button @click="exportTable">导出报表</el-button>
                </div>
            </div>
        </el-card>
        <el-card>
            <div class="notice-list-box">
                <div class="notice-table">
                    <el-table :data="czData" style="width: 100%">
                        <el-table-column prop="created_at" label="操作时间" width="180"></el-table-column>
                        <el-table-column prop="opt_user" label="操作人"></el-table-column>
                        <el-table-column prop="req_route" label="操作模块"></el-table-column>
                        <el-table-column label="关联事项" width="500">
                            <template slot-scope="scope">
                                <div class="scope-log-title">
                                    <div class="log-title">{{ scope.row.log_title }}</div>
                                    <div class="log-detail" v-if="scope.row.log_title" @click="showDetail(scope.row)">查看详情
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="req_ip" label="IP"></el-table-column>
                        <el-table-column prop="req_ua_type" label="浏览器" align="right"></el-table-column>
                    </el-table>
                </div>
            </div>
            <div class="pagination">
                <el-pagination @size-change="handleSizeChange" :hide-on-single-page="false"
                    @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-sizes="[10, 20, 30, 40]"
                    :page-size="eachPage" layout="total, sizes, prev, pager, next, jumper" :pager-count="5"
                    :total="total_entry">
                </el-pagination>
            </div>
        </el-card>
        <el-dialog title="详情" :visible.sync="dialogTableVisible">
            <div class="dialog-box" v-for="item in detailTable" :key="item.id">
                <div class="dialog-title">{{ item.name }}</div>
                <el-table :data="item.log_data">
                    <el-table-column property="name" label="属性"></el-table-column>
                    <el-table-column property="old" label="修改前"></el-table-column>
                    <el-table-column property="new" label="修改后"></el-table-column>
                </el-table>
            </div>

        </el-dialog>

        <!-- 导出报表 -->
        <diy-export-more ref="diyExportMore" dialogTitle="导出分组" dialogExportKeys="operation.log.export.field.list"
            dialogExportUrl="/operate/export" :dialogExportIsSearch="true"></diy-export-more>
    </div>
</template>

<script>
export default {
    data() {
        return {
            staffList: [],
            steffIndex: '',
            resourceList: [],
            resourceIndex: '',
            relatedMatters: '',
            noticeTime: '',
            pickerOptionLater: {
                // 设定时间选择区间截止为当天
                disabledDate(time) {
                    var date = new Date(new Date().setHours(23, 59, 59, 59)).getTime()
                    return time.getTime() > date
                },
                shortcuts: [{
                    text: '今天',
                    onClick(picker) {
                        const start = new Date().setHours(0, 0, 0, 0);
                        const end = new Date().setHours(23, 59, 59, 59);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '7天',
                    onClick(picker) {
                        const end = new Date().setHours(23, 59, 59, 59);
                        const start = new Date();
                        start.setTime(end - (3600 * 1000 * 24 * 7 - 1000));
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '30天',
                    onClick(picker) {
                        const end = new Date().setHours(23, 59, 59, 59);
                        const start = new Date();
                        start.setTime(end - (3600 * 1000 * 24 * 30 - 1000));
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            // 时间快捷选择
            timeLastLabel: ['今天', '昨天', '7天', '30天'],
            // 时间快捷选择标识
            timeLastIndex: -1,
            currentPage: 1,
            eachPage: 10,
            total_entry: 0,
            czData: [],
            detailTable: [],
            dialogTableVisible: false
        }
    },
    created() {
        // 获取操作人列表
        this.getCzSteff()
        // 获取模块接口
        this.getResource()

        // 获取操作数据
        this.handleSumbit()
    },
    methods: {
        getCzSteff() {
            this.$get(this.$apis.sysStaffLogIdName).then(res => {
                if (res.code == 200) {
					console.log(res.data)
                    this.staffList = res.data
                }
            })
        },
        getResource() {
            this.$get(this.$apis.resource, { 'keys': ['operate_log_type'] }).then(res => {
                if (res.code == 200) {
                    this.resourceList = res.data.operate_log_type;
                }
            })
        },
        handleSizeChange(val) {
            this.eachPage = val;
            this.handleSumbit();
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.handleSumbit();
        },
		handleSumbited(){
			this.currentPage = 1;
			this.handleSumbit();
		},
        handleLastTime(e) {
            if (this.timeLastIndex == e) return false
            this.timeLastIndex = e
            let timeBetween, startTime, lastTime;
            const _start = new Date(new Date().toLocaleDateString());
            switch (e) {
                case 0:
                    startTime = new Date(new Date().setHours(0, 0, 0, 0));
                    lastTime = new Date(new Date().setHours(23, 59, 59, 59));
                    timeBetween = [startTime, lastTime]
                    break;
                case 1:
                    startTime = _start.setTime(_start.getTime() - 3600 * 1000 * 24 * 1);
                    lastTime = new Date(new Date(startTime).setHours(23, 59, 59, 59));
                    timeBetween = [new Date(startTime), lastTime]
                    break;
                case 2:
                    startTime = _start.setTime(_start.getTime() - 3600 * 1000 * 24 * 7);
                    lastTime = new Date(new Date().setHours(23, 59, 59, 59));
                    timeBetween = [new Date(startTime), lastTime]
                    break;
                case 3:
                    startTime = _start.setTime(_start.getTime() - 3600 * 1000 * 24 * 30);
                    lastTime = new Date(new Date().setHours(23, 59, 59, 59));
                    timeBetween = [new Date(startTime), lastTime]
                    break;
            }
            this.noticeTime = timeBetween;
        },
        showDetail(value) {
            this.$get(this.$apis.sysOperateLogDetail + value.id).then(res => {
                console.log(res, 'sysOperateLogDetail');
                if (res.code == 200) {
                    this.detailTable = res.data.opt_data;
                    this.dialogTableVisible = true;
                }
            })
        },
        resetting() {
            this.steffIndex = ""
            this.resourceIndex = ""
            this.relatedMatters = ""
            this.noticeTime = ""
            this.handleSumbit()
        },
        // 提取搜索数据
        extractSearch() {
            let data = {
                search: [],
                order: [],
                page: this.currentPage,
                limit: this.eachPage
            };

            // 整理搜索数据
            if (this.steffIndex) {
                const _data = {
                    "key": "user_id",
                    "value": this.steffIndex,
                    "op": "equal"
                }
                data.search.push(_data)
            }
            if (this.resourceIndex) {
                let _data = {
                    "key": "opt_type",
                    "value": this.resourceIndex,
                    "op": "equal"
                }
                data.search.push(_data)
            }
            if (this.relatedMatters) {
                let _data = {
                    "key": "log_title",
                    "value": this.relatedMatters,
                    "op": "search"
                }
                data.search.push(_data)
            }
            if (this.noticeTime) {
                let _data = {
                    "key": "created_at",
                    "value": [this.common.timeStampTurnTime(this.noticeTime[0]), this.common.timeStampTurnTime(this.noticeTime[1])],
                    "op": "between"
                }
                data.search.push(_data)
            }

            return data
        },
        // 打开导出报表弹窗
        exportTable() {
            this.$refs.diyExportMore.exportMore = true
        },
        dataPickerChange(event) {
            console.log(event);
        },
        handleSumbit() {
            const data = this.extractSearch()

            console.log(data, 'sysOperateLogsysOperateLog');

            this.$get(this.$apis.sysOperateLog, data).then(res => {
                if (res.code == 200) {
                    this.czData = res.data.list;
                    this.total_entry = res.data.total;
                } else {
                    this.common.message(this, res.message);
                }
            }).catch(err => {
                // console.log(err)
                this.common.message(this, err.message);
            });

        }
    }
}
</script>

<style lang="scss" scoped>
.scope-log-title {
    display: flex;
    align-items: center;

    .log-title {
        max-width: 240px;
        word-wrap: break-word;
        /*强制换行*/
        overflow: hidden;
        /*超出隐藏*/
        text-overflow: ellipsis;
        /*隐藏后添加省略号*/
        white-space: nowrap;
        /*强制不换行*/
    }

    .log-detail {
        color: var(--fontColor);
        margin-left: 10px;
        cursor: pointer;
    }
}

.pagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
}

.dialog-box {
    padding: 30px;
    box-sizing: border-box;

    .dialog-title {
        font-size: 14px;
        color: #333;
        padding-bottom: 10px;
        padding-left: 10px;
        font-weight: bold;
    }
}


/deep/ .el-table {
    color: #333 !important;
}

// 表头的样式处理
/deep/ .el-table .el-table__header tr {
    background-color: #f9f9fb;
    color: #333333;
}

/deep/ .el-table .el-table__header th.el-table__cell {
    background-color: #f9f9fb;
    color: #333333;
}
</style>